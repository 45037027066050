import { TSideBarRouts } from "./type.ts";
import PwaIcon from "../../../assets/icons/PwaIcon.tsx";
import StatistikaIcon from "../../../assets/icons/StatistikaIcon.tsx";

export const sideBarRoutesData: TSideBarRouts[] = [
  {
    navigate: "profile",
    name: "myPwa",
    Icon: PwaIcon,
  },
  {
    navigate: "fbapi",
    name: "Stats",
    Icon: StatistikaIcon,
  },
];
