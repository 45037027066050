import styles from "./styles.module.scss";
import {FC, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import type {TPwaSelectProps} from "./types.ts";
import {useAppDispatch} from "../../../lib/redux/hooks.ts";
import {
    installStatisticsThunk,
    viewStatisticsThunk,
} from "../../../features/statistics/statisticsServices.ts";
import {detailedThunk} from "../../../features/Detailed/detailedServices.ts";

const PwaSelect: FC<TPwaSelectProps> = ({data}) => {
    const [age, setAge] = useState("");
    const dispatch = useAppDispatch();
    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    };

    const handleSelect = (id: number | string) => {
        dispatch(viewStatisticsThunk(id));
        dispatch(installStatisticsThunk(id));
        dispatch(detailedThunk(+id))
    };

    return (
        <div className={styles.wrapper}>
            <Select
                sx={{
                    width: "100%",
                    "&.Mui-focused": {
                        ".MuiOutlinedInput-notchedOutline": {borderColor: "green"},
                    },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
            >
                {data &&
                    data.map((element, index) => {
                        return (
                            <MenuItem
                                key={index}
                                onClick={() => handleSelect(element.id)}
                                value={element.name}
                            >
                                {element.name}
                            </MenuItem>
                        );
                    })}
            </Select>
        </div>
    );
};

export default PwaSelect;
