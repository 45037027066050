import {initializeApp} from "firebase/app";
import { getMessaging as getMessagingFirebase, isSupported } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyDGQr8STY2IsNZFHslN0Amarvaei39QqKw",
    authDomain: "my-project-1533032601343.firebaseapp.com",
    databaseURL: "https://my-project-1533032601343.firebaseio.com",
    projectId: "my-project-1533032601343",
    storageBucket: "my-project-1533032601343.appspot.com",
    messagingSenderId: "627047749993",
    appId: "1:627047749993:web:2327a73e579c185c1345f8"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service

export const messaging = await (async () => {
  try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
          return getMessagingFirebase(app);
      }
      console.log('Firebase not supported this browser');
      return null;
  } catch (err) {
      console.log(err);
      return null;
  }
  })();

