import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {authSlice} from "../../features/auth/authSlice.ts";
import {templateSlice} from "../../features/templates/templatesSlice.ts";
import {phoneInfoSlice} from "../../features/phoneInfo/phoneImfoSlice.ts";
import {resetPasswordSlice} from "../../features/resetPassword/resetPasswordSlice.ts";
import {createdPwaSlice} from "../../features/createdPwa/createdPwaSlice.ts";
import {updatePwaSlice} from "../../features/updatePwa/updatePwaSlice.ts";
import {ownPwaSlice} from "../../features/ownPwa/ownPwaSlice.ts";
import {getPwaSlice} from "../../features/getPwa/getPwaSlice.ts";
import {deletePwaSlice} from "../../features/deletePwa/deletePwaSlice.ts";
import {settingSlice} from "../../features/settings/settingsSlice.ts";
import {customCommentsSlice} from "../../features/customComments/customCommentsSlice.ts";
import {uploadDesignSlice} from "../../features/uploadDesign/uploadDesignslice.ts";
import {getPwaByIdSlice} from "../../features/getPwaById/getPwaByIdSlice.ts";
import {fbTokenSlice} from "../../features/fbTokens/fbTokensSlice.ts";
import {statisticsSlice} from "../../features/statistics/ststisticsSlice.ts";
import {apkSlice} from "../../features/Apk/apkSlice.ts";
import {detailedSlice} from "../../features/Detailed/detailedSlice.ts";

export interface IDefaultState<T = undefined> {
    data: T | null;
    loading: boolean;
    error?: string | null;
}

const rootReducers = combineReducers({
    createdPwa: createdPwaSlice.reducer,
    auth: authSlice.reducer,
    template: templateSlice.reducer,
    phoneInfo: phoneInfoSlice.reducer,
    resetPassword: resetPasswordSlice.reducer,
    updatePwa: updatePwaSlice.reducer,
    ownPwa: ownPwaSlice.reducer,
    getPwa: getPwaSlice.reducer,
    deletePwa: deletePwaSlice.reducer,
    settings: settingSlice.reducer,
    customComments: customCommentsSlice.reducer,
    uploadDesign: uploadDesignSlice.reducer,
    getPwaById: getPwaByIdSlice.reducer,
    fbToken: fbTokenSlice.reducer,
    statistics: statisticsSlice.reducer,
    apk: apkSlice.reducer,
    detailed: detailedSlice.reducer
});

export const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({immutableCheck: false, serializableCheck: false}),
});

export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = typeof store.dispatch;
