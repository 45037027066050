import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProfilePage from "../../pages/ProfilePage";
import MainLayout from "../../common/Layout/MainLayout";
import FbApi from "../../pages/FbApi";
import Design from "../../pages/Design";
import Domain from "../../pages/Domain";
import Application from "../../pages/Application";
import Settings from "../../pages/Settings";
import CommentsPage from "../../pages/CommentsPage";
import FacebookTokens from "../../pages/FacebookTokens";

const MainRoutes: React.FC = () => {
  return (
    <MainLayout>
      <Routes>
         <Route path="/" element={<Navigate to="/profile" />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/fbapi" element={<FbApi />} />
        <Route path="/design" element={<Design />} />
        <Route path="/domain" element={<Domain />} />
        <Route path="/application" element={<Application />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/comment" element={<CommentsPage />} />
        <Route path="/fbtokens" element={<FacebookTokens />} />
         <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </MainLayout>
  );
};

export default MainRoutes;
