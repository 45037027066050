import { useEffect } from "react";
import { useAppSelector } from "./lib/redux/hooks.ts";
import { messaging } from "./firebase.ts";
import { getToken, Messaging } from "firebase/messaging";

const isSupported = () =>
  "Notification" in window &&
  "serviceWorker" in navigator &&
  "PushManager" in window;
  
const Downloadable = () => {
  const storage_url = useAppSelector((state) => state.apk.data?.url);
  const url = localStorage.getItem("_pwa_app_url");

  const requestPermission = async () => {
    //requesting permission using Notification API
    if (isSupported()) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        if (messaging) {
          const token = await getToken(messaging as unknown as Messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
          });

          //We can send token to server
          console.log("Token generated : ", token);
        }
      } else if (permission === "denied") {
        //notifications are blocked
        console.log("You denied for the notification");
      }
    }
  };

  useEffect(() => {
    requestPermission();
    // if (messaging) {
    //   return onMessage(messaging as unknown as Messaging, (payload) => {
    //     console.log(payload);
    //   });
    // }
  }, []);

  useEffect(() => {
    if (storage_url) {
      window.location.href = storage_url;
    }
  }, [url, storage_url]);

  return null;
};

export default Downloadable;
